import styled, { keyframes } from "styled-components";
import { auth } from "../firebaseConfig"; // auth import 추가
import PostInput from "../components/PostInput";
import Timeline from "../components/Timeline";

// 애니메이션 정의
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// styled-component를 통한 css 구성
const Container = styled.div`
  animation: ${fadeInUp} 1s ease-out; /* 애니메이션 적용 */
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #ccc;
`;

const LogoutButton = styled.button`
  position: fixed; /* 위치를 고정 */
  top: 20px;
  right: 20px;
  background-color: #ff4d4f;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d9363e;
  }
`;

// 실제 페이지를 구성하는 코드
export default () => {
  // 로그아웃 함수
  const signOut = async () => {
    await auth.signOut();
    window.location.reload();
    alert("Logged out successfully!");
  };

  return (
    <>
      <LogoutButton onClick={signOut}>Logout</LogoutButton> {/* 버튼을 별도 렌더링 */}
      <Container>
        <Title>Gosky</Title>
        {/* 게시물 작성하기 */}
        <PostInput />
        <Timeline />
      </Container>
    </>
  );
};
