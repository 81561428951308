// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth' // 인증 관련 모듈
import {getFirestore} from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCjta7r3WYmQZaFO7BQYJNV4o9PTdSfuiM",
  authDomain: "daelimx-434b8.firebaseapp.com",
  projectId: "daelimx-434b8",
  storageBucket: "daelimx-434b8.firebasestorage.app",
  messagingSenderId: "501328892093",
  appId: "1:501328892093:web:086a3c52dacb10c48da7d4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// 1. 인증 Authentication with "app"
export const auth = getAuth(app);
// 2. DB firestor with "app"
export const firestore = getFirestore(app);