import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from "firebase/auth";
import React, { useState } from "react";
import styled from "styled-components";
//import LogoImg from "../assets/images/DaelimX_title.png";
import { auth } from "../firebaseConfig"; // Firebase 설정 가져오기
import { useNavigate } from "react-router-dom";
import { FirebaseError } from "firebase/app";
import { Link } from 'react-router-dom';
import { env } from "process";

import GoogleSignupBtn from "../components/GoogleSignupBtn";
import EmailSignUpBtn from "../components/EmailSignUpBtn";

// 나머지 코드...

// styled-Component를 통한 css구성
const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  padding: 30px;
  /* 특정 화면 너비가 되었을 때 실행 */
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

// 화면 타이틀 텍스트
const Title = styled.h1`
  color: white;
  font-size: 20px;
  font-weight: 600;
`;
// 화면 타이틀 로고(이미지)
const Logo = styled.img`
    width: 100%;
    max-width: 550px;
    height: auto;
`;
// 화면 ID/PW 텍스트 Input Field(Form)
// ㄴ1. Form (텍스트 인풋 필드를 담을 공간)
const Form = styled.form`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  /* background-color: tomato; */
  width: 400px;
`;
// ㄴ2. rkrrkr(id, pw)의 Input Field
const Input = styled.input`
  padding: 10px 15px;
  border-radius: 10px;
  border: none;
  &::placeholder {
    font-size: 0.8em;
  }
  &[type="submit"] {
    margin-top: 30px;
    cursor: pointer;
  }
`;
const InputTitle = styled.p`
  color: white;
  font-size: 8px;
  margin-top: 10px;
  margin-bottom: 5px;
`;

// 회원가입 버튼
const SigninBtn = styled.div`
  padding: 10px 15px;
  background-color: white;
  border-radius: 20px;
  /* width: 100%; */
  cursor: pointer;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  color: black;
`;
// 에러메시지 출력 컴포넌트
const ErrorMsg = styled.p`
  color: red;
  font-weight: 600;
  font-size: 10px;
  /* text-align: center; */
  width: 100%;
`;
// 로그인 페이지 이동을 위한 Guide
const Guide = styled.span`
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
    a {
        color: #49eb08;
        font-weight: bold;
    }
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
// 로그인 / 가입 버튼 구분
const Divider = styled.p`
    display: flex;
    color: white;
    align-items: center;
    text-align: center;
    &::before,
    &::after{
        content: "";
        flex:1;
        border-bottom: 1px solid white;
        margin: 30px 5px;
    }
`
const GuidTitle = styled.p`
    font-size: 13px;
    font-weight: 500;
    text-align: center;
`


// 실제 페이지를 구성하는 code
export default () => {
  // 회원가입을 하기 위한 Process 코드작성
  // 페이지 이동을 위한 Hook
  const navigation = useNavigate();

  // A. 회원정보를 저장할 데이터(State)-StateHook
  const [email, setEmail] = useState(""); // Email
  const [password, setPassword] = useState(""); // password
  const [loading, setLoading] = useState(false); // Loading
  const [error, setError] = useState(""); // Error

  // B. 회원정보를 입력할 때 실행 (유저가 입력한 정고 가공/수정)
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 0. 입력된 정보에서 값(입력위치, 입력밧)을 알아야한다.
    // const name = e.target.name; // ㄴ입력위치
    // const value = e.target.value; // ㄴ입력값
    // 1. 어디서 입력된 정보(event)인지 알아야 함
    const {
      target: { name, value },
    } = e;
    // 2. 입력된 장소(name, email, pw)에 따라 각각 기능
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
    }
    // 3. 저장한 데이터를 Page에 보여준다
  };

  // C. 로그인 버튼 눌렀을 때, 실행(입력한 정보 전달)
  const onSubmit = async () => {
    // A. 방어코드:: 아무것도 입력하지 않고 제출X(그만)
    console.log("눌림");
    if (email === "" || password === "") {
      // 로그인 프로세스를 진행하지 않고 종료.
      alert("회원정보를 입력해주세요");
      return;
    }
    // B. 로그인 프로세스 진행
    try {
      // 로그인 프로세스 진행
      // b-1. 로딩 시작...
      setLoading(true);
      // b-2. 사용자가 입력한 전달할 데이터 확인
      // b-3. API를 통해서 Server에 값(입력한 회원정보)
      // b-4. 서버에서.... 로그인 정보 확인..
      const credential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      // b-5. 로그인 화면으로 이동 or 자동 로그인 => 홈화면
      navigation("/");
    } catch (e) {
      console.log(e);
      // [Firebase error]-- 통신X, 중복된 이메일, 잘못된PW..
      // 1. 만일 발생한 에러가 Firebase Error라면?
      if (e instanceof FirebaseError) {
        // 2. Firebase 에러 메시지를 화면에 출력
        setError(e.code);
      }
      // - 에러 메시지 출력
    } finally {
    }

    //-로딩 종료
  };

  // 페이지 레이아웃(Design)반환 --- Rendering
  return (
    <Container>
      <Logo src={`${process.env.PUBLIC_URL}/DaelimX_Title.png`}></Logo>
      <Form>
        <Title>로그인</Title>
        <InputTitle>이메일*</InputTitle>
        <Input
          onChange={onChange}
          name="email"
          type="email"
          placeholder="예) Daelim@daelim.ac.kr"
          value={email}
        />
        <InputTitle>비밀번호*</InputTitle>
        <Input
          onChange={onChange}
          name="password"
          type="password"
          placeholder="**********"
          value={password}
        />
        {/* <Input onSubmit={onSubmit} type="submit" value={loading===true? "로딩중" : "회원가입"}/> */}
        <SigninBtn onClick={onSubmit}>
          {loading ? "로딩중..." : "로그인"}
        </SigninBtn>
        <ErrorMsg>{error}</ErrorMsg>

        <Divider>또는</Divider>
        <Guide>
            <GuidTitle>계정을 만드시겠어요?</GuidTitle>
            <EmailSignUpBtn/>
            <GoogleSignupBtn/>
        </Guide>
      </Form>
    </Container>
  );
};
