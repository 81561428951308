import { useRef, useState } from "react";
import styled from "styled-components";
import { auth, firestore } from "../firebaseConfig";
import { addDoc, collection } from "firebase/firestore";
import { IPost } from "../types/PostInput";

// 새로운 스타일 정의
const Form = styled.form`
    display: flex;
    align-items: flex-start;
    gap: 15px;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Profile = styled.div`
    background-image: url('https://tech.comon.kr/node_api/images/1733661348538-979524837.png');
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    flex-shrink: 0;
`;

const PostArea = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const TextArea = styled.textarea`
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    resize: none;
    font-size: 16px;
    font-family: inherit;
    min-height: 80px;
    max-height: 200px;
    overflow-y: auto;
    &:focus {
        border-color: #007BFF;
        outline: none;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
    &::placeholder {
        color: #aaa;
    }
`;

const BottomMenu = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
`;

const AttachFileButton = styled.label`
    display: flex;
    align-items: center;
    background-color: #007BFF;
    color: white;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
        background-color: #0056b3;
    }
`;

const AttachFileInput = styled.input`
    display: none;
`;

const SubmitButton = styled.button`
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
        background-color: #218838;
    }
    &:disabled {
        background-color: #6c757d;
        cursor: not-allowed;
    }
`;

const UploadIndicator = styled.span`
    font-size: 12px;
    color: #555;
    margin-left: 10px;
`;

const PostForm = () => {
    const [post, setPost] = useState<string>("");
    const [file, setFile] = useState<File | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setPost(value);
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length === 1) {
            setFile(files[0]);
        }
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        try {
            const user = auth.currentUser;
            if (!user || !post.trim()) {
                alert("내용을 입력해 주세요.");
                return;
            }

            const myPost: IPost = {
                post: post,
                createdAt: Date.now(),
                nickname: user.displayName || "익명",
                userId: user.uid,
                email: user.email || "",
                // 필요에 따라 파일 업로드 로직 추가 가능
            };

            await addDoc(collection(firestore, "posts"), myPost);
            setPost("");
            setFile(null);
            alert("게시글이 성공적으로 업로드되었습니다.");
        } catch (error) {
            console.error("게시글 업로드 실패:", error);
            alert("게시글 업로드에 실패했습니다. 다시 시도해 주세요.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form onSubmit={onSubmit}>
            <Profile />
            <PostArea>
                <TextArea
                    ref={textareaRef}
                    value={post}
                    onChange={onChange}
                    maxLength={200}
                    placeholder="무슨 일이 일어나고 있나요?"
                />
                <BottomMenu>
                    <AttachFileButton htmlFor="file">
                        사진 업로드
                        {file && <UploadIndicator>{file.name}</UploadIndicator>}
                    </AttachFileButton>
                    <AttachFileInput
                        onChange={onChangeFile}
                        type="file"
                        id="file"
                        accept="image/*"
                    />
                    <SubmitButton type="submit" disabled={loading}>
                        {loading ? "업로드 중..." : "작성하기"}
                    </SubmitButton>
                </BottomMenu>
            </PostArea>
        </Form>
    );
};

export default PostForm;
