import styled from "styled-components";
import { IPost } from "../types/PostInput";
import { auth, firestore } from "../firebaseConfig";
import moment from "moment";
import PostMenu from "./Post-Menu";
import { deleteDoc, doc } from "firebase/firestore";
import { useState } from "react";

// 새로운 스타일 정의
const Container = styled.div`
  display: flex;
  gap: 15px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 15px;
  }
`;

const ProfileBox = styled.div`
  flex-shrink: 0;
`;

const Photo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
`;

const DataBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Topbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserName = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #333333;
`;

const UserEmail = styled.span`
  font-size: 14px;
  color: #6d8df0;
  margin-top: 2px;
`;

const DeleteBtn = styled.button`
  background-color: #ff4d4f;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d9363e;
  }

  &:disabled {
    background-color: #ffcccc;
    cursor: not-allowed;
  }
`;

const Content = styled.div`
  margin: 15px 0;
  font-size: 16px;
  color: #444444;
  line-height: 1.6;
  white-space: pre-wrap; /* Preserve line breaks */
`;

const CreateTime = styled.div`
  font-size: 12px;
  color: #888888;
  margin-bottom: 10px;
`;

const Footer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: auto; /* Push footer to the bottom */
`;

const FooterItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  color: #555555;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #007bff;
  }
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  background-color: #e3e3e3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #007bff;
  font-size: 14px;
`;

const Post = ({ id, userId, nickname, post, createdAt, email }: IPost) => {
  const user = auth.currentUser;
  const [isDeleting, setIsDeleting] = useState(false);

  // 게시글 삭제
  const onDelete = async () => {
    // 방어 코드: 게시글 ID가 없거나 현재 사용자가 게시글 작성자가 아닌 경우
    if (!id || user?.uid !== userId) {
      alert("삭제 권한이 없습니다.");
      return;
    }

    const isOK = window.confirm("정말 이 게시글을 삭제하시겠습니까?");
    if (!isOK) return;

    setIsDeleting(true);
    try {
      const postRef = doc(firestore, "posts", id);
      await deleteDoc(postRef);
      alert("게시글이 삭제되었습니다.");
      // 추가적으로, 삭제 후의 동작 (예: 리스트에서 제거) 필요 시 구현
    } catch (error) {
      console.error("게시글 삭제 실패:", error);
      alert("게시글 삭제에 실패했습니다. 다시 시도해 주세요.");
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Container>
      {/* 프로필 이미지 */}
      <ProfileBox>
        <Photo src="https://tech.comon.kr/node_api/images/1733661348538-979524837.png" alt={`${nickname}의 프로필`} />
      </ProfileBox>
      {/* 게시글 데이터 */}
      <DataBox>
        <Topbar>
          <UserInfo>
            <UserName>{nickname}</UserName>
            <UserEmail>{email}</UserEmail>
          </UserInfo>
          {user?.uid === userId && (
            <DeleteBtn onClick={onDelete} disabled={isDeleting}>
              {isDeleting ? "삭제 중..." : "삭제"}
            </DeleteBtn>
          )}
        </Topbar>
        <Content>{post}</Content>
        <CreateTime>{moment(createdAt).fromNow()}</CreateTime>
        <Footer>
          <FooterItem>
            <IconWrapper>👁️</IconWrapper>
            <span>99</span>
          </FooterItem>
          <FooterItem>
            <IconWrapper>❤️</IconWrapper>
            <span>23</span>
          </FooterItem>
          <FooterItem>
            <IconWrapper>💬</IconWrapper>
            <span>32</span>
          </FooterItem>
        </Footer>
      </DataBox>
    </Container>
  );
};

export default Post;
