import styled, { keyframes } from "styled-components";
import { auth } from "../firebaseConfig";
import { signOut } from "firebase/auth"; // 로그아웃 기능을 위해 import
import { useState } from "react";

// 페이드 인 애니메이션 정의
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// styled-components를 통한 스타일 정의
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white; 
  text-align: center;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 15px;
    height: auto;
    min-height: 100vh;
  }
`;

const Photo = styled.img`
  background-color: #ccc;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const Title = styled.h1`
  font-size: 3rem; /* 제목 크기 증가 */
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* 제목에 그림자 효과 */
  animation: ${fadeIn} 1.5s ease;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 30px;
  max-width: 600px; /* 부제목 폭 제한 */
  line-height: 1.6;
  animation: ${fadeIn} 2s ease;
  
  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
`;

const EmailDisplay = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 15px;
  color: #f0f0f0;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* 이메일에 그림자 효과 */

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Button = styled.button`
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  background: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  margin-top: 25px;

  &:hover {
    background: #0056b3;
    transform: scale(1.05); /* 버튼 호버 시 확대 */
  }

  &:active {
    transform: scale(0.98);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
`;

const ErrorMessage = styled.p`
  color: #ffdddd;
  background-color: #ff4d4f;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;
  animation: ${fadeIn} 0.5s ease;
`;

const SuccessMessage = styled.p`
  color: #ddffdd;
  background-color: #28a745;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;
  animation: ${fadeIn} 0.5s ease;
`;

// 실제 페이지를 구성하는 컴포넌트
const ProfilePage = () => {
  const user = auth.currentUser; // Firebase 인증으로 현재 사용자 가져오기
  const userEmail = user?.email || "등록된 이메일이 없습니다."; // 로그인한 사용자의 이메일
  const [message, setMessage] = useState<{ type: string; text: string } | null>(null); // 메시지 상태 관리
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태 관리

  // 로그아웃 핸들러
  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await signOut(auth);
      window.location.reload();
      setMessage({ type: "success", text: "성공적으로 로그아웃 되었습니다." });
    } catch (error) {
      console.error("로그아웃 실패:", error);
      setMessage({ type: "error", text: "로그아웃에 실패했습니다. 다시 시도해 주세요." });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      {/* 프로필 사진 표시 (사용자 사진이 있는 경우) */}
      {user?.photoURL ? (
        <Photo src={user.photoURL} alt={`${user.displayName || "사용자"}의 프로필 사진`} />
      ) : (
        <Photo src="https://tech.comon.kr/node_api/images/1733661348538-979524837.png" alt="기본 프로필 사진" />
      )}
      <Title>환영합니다, {user?.displayName || "사용자"}님!</Title>
      <Subtitle>
        프로필을 관리하고, 활동을 확인해보세요.
      </Subtitle>
      <EmailDisplay>
        등록된 이메일: {userEmail}
      </EmailDisplay>
      <Button onClick={handleLogout} disabled={isLoading}>
        {isLoading ? "안전하게 로그아웃 중..." : "안전하게 로그아웃"}
      </Button>
      {message && message.type === "error" && (
        <ErrorMessage>{message.text}</ErrorMessage>
      )}
      {message && message.type === "success" && (
        <SuccessMessage>{message.text}</SuccessMessage>
      )}
    </Container>
  );
};

export default ProfilePage;
