import { useEffect, useState } from "react";
import styled from "styled-components";
import { firestore } from "../firebaseConfig"; // firebaseConfig에서 firestore import
import { collection, getDocs, onSnapshot, orderBy, query } from "firebase/firestore";
import { IPost } from "../types/PostInput"; // Post 타입을 import
import Post from "./Post"; // Post 컴포넌트 import
import { Unsubscribe } from "firebase/auth"; // Firebase에서 반환하는 구독 해제 타입 import

const Container = styled.div`
  margin: 0 auto;
  max-width: 800px;
  padding: 20px;
`;

export default () => {
    // 게시글 '들'을 저장할 State
    const [posts, setPosts] = useState<IPost[]>([]);

    // Realtime으로 게시글을 불러오는 함수
    useEffect(() => {
        let unSubscribe: Unsubscribe | null = null;

        // Firebase DB에서 게시글을 실시간으로 불러오는 쿼리 생성
        const getPostsRealtime = async () => {
            const path = collection(firestore, "posts");
            const condition = orderBy("createdAt", "desc");
            const postsQuery = query(path, condition);
            
            // onSnapshot을 사용해 실시간 구독 시작
            unSubscribe = onSnapshot(postsQuery, (snapshot) => {
                const timelinePosts = snapshot.docs.map((doc) => {
                    const { createdAt, email, nickname, post, userId } = doc.data() as IPost;
                    return {
                        id: doc.id,  // 추가: doc.id를 id로 사용
                        createdAt,
                        email,
                        nickname,
                        post,
                        userId
                    };
                });
                setPosts(timelinePosts);
            });
        };

        getPostsRealtime();  // 실시간 게시글 구독 시작

        // 컴포넌트가 unmount될 때 구독 해제
        return () => {
            if (unSubscribe) {
                unSubscribe();
            }
        };
    }, []); // 빈 배열로 설정하여 컴포넌트가 처음 마운트될 때만 실행

    // 렌더링 부분
    return (
        <Container>
            {posts.map((post) => {
                return <Post key={post.id} {...post} />;
            })}
        </Container>
    );
};
